import React, { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import CmnContactArea from './CmnContactArea';
import ScrollTopIcon from './ScrollTopIcon';
import ScrollTopTran from './ScrollTopTran';
import Header from './Header';
import Footer from './Footer';

const Contact = () => {
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [furigana, setFurigana] = useState("");
    const [tel, setTel] = useState("");
    const [message, setMessage] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [nameError, setNameError] = useState('')
    const [furiganaError, setFuriganaError] = useState('')
    const [emailError, setEmailError] = useState('')
    const [telError, setTelError] = useState('')
    const [messageError, setMessageError] = useState('')

    /**
     * お問い合わせフォームで「確認する」ボタンを押下した時の処理
     */ 
    const handleConfirm = (e) => {
        e.preventDefault();

        // バリデーションチェック
        let arryErrResult = { name: false, furigana: false, email: false, tel: false, message: false };
        arryErrResult.name = cmnChkName(name);
        arryErrResult.furigana = cmnChkFurigana(furigana);
        arryErrResult.email = cmnChkEmail(email);
        arryErrResult.tel = cmnChkTel(tel);
        arryErrResult.message = cmnChkMessage(message);

        if (arryErrResult.name || arryErrResult.furigana || arryErrResult.email || arryErrResult.tel || arryErrResult.message) {
            return;
        } else {
            setShowConfirmation(true); // チェック結果がOKなら、確認画面へ。
        }
    };
    
    /**
     * 確認画面で「送信」ボタンを押下した時の処理
     */ 
    const handleSubmit = (e) => {
        e.preventDefault();

        // const url = "http://localhost:4300/rest/send.php";
        const url = "https://nijico-kind.com/rest/send.php"
        axios.post(url, {
            'name': name,
            'furigana': furigana,
            'email': email, 
            'tel' : tel,
            'message': message
        })
        .then(response => {
            let error = response.data.error

            if (!error) {
                setFormSubmitted(true)
            } else {
                console.log(response.data);
                navigate('/error');
            }
        })
        .catch(error => {
            console.log(error)
            navigate('/error');
        })
    }
    
    /**
     * 確認画面で「戻る」ボタンを押下した時の処理
     */ 
    const handleBack = () => {
        setShowConfirmation(false);
    };
    
    /**
     * 内部値のリセット処理
     */ 
    const handleReset = () => {
        setName("");
        setFurigana("");
        setEmail("");
        setTel("");
        setMessage("");
        setShowConfirmation(false);
        setFormSubmitted(false);
    };
    
    /**
     * nameテキストボックス にロストフォーカスされた時の処理
     */ 
    const handleBlurName = (name) => {
        cmnChkName(name); //Nameの共通チェック処理を呼び出す
    }

    const cmnChkName = (value) => {
        let flgErr = false;

        if (!value) {
            setNameError('お名前は、必須項目です。');
            flgErr = true;
        } else {
            setNameError('');
        }

        return flgErr;
    }

    /**
     * furiganaテキストボックス にロストフォーカスされた時の処理
     */ 
    const handleBlurFurigana = (furigana) => {
        cmnChkFurigana(furigana); //furiganaの共通チェック処理を呼び出す
    }

    const cmnChkFurigana = (value) => {
        let flgErr = false;

        if (!value) {
            setFuriganaError('ふりがなは、必須項目です。');
            flgErr = true;
        } else {
            setFuriganaError('');
        }

        return flgErr;
    }

    /**
     * emailテキストボックス にロストフォーカスされた時の処理
     */ 
    const handleBlurEmail = (email) => {
        cmnChkEmail(email); //Emailの共通チェック処理を呼び出す
    }

    const cmnChkEmail = (value) => {
        let flgErr = false;
        const regex = /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;

        if (!value) {
            setEmailError('メールアドレスは、必須入力です。');
            flgErr = true;
        } else if (value.length < 3) {
            setEmailError('メールアドレスは、3文字以上で入力してください。');
            flgErr = true;
        } else if (!regex.test(value)) {
            setEmailError('メールアドレスの形式で入力してください。');
            flgErr = true;
        } else {
            setEmailError('');
        }

        return flgErr;
    }

    /**
     * telテキストボックス にロストフォーカスされた時の処理
     */ 
    const handleBlurTel = (tel) => {
        cmnChkTel(tel); //telの共通チェック処理を呼び出す
    }

    const cmnChkTel = (value) => {
        let flgErr = false;
        const regex = /^0\d{9,10}$/;

        if (!value) {
            setTelError('電話番号は、必須入力です。');
            flgErr = true;
        } else if (value.length < 3) {
            setTelError('電話番号は、3文字以上で入力してください。');
            flgErr = true;
        } else if (!regex.test(value)) {
            setTelError('有効な電話番号を入力してください。');
            flgErr = true;
        } else {
            setTelError("");
        }

        return flgErr;
    }

    /**
     * messageテキストボックス にロストフォーカスされた時の処理
     */ 
    const handleBlurMessage = (message) => {
        cmnChkMessage(message); //Messageの共通チェック処理を呼び出す
    }

    const cmnChkMessage = (value) => {
        let flgErr = false;

        if (!value) {
            setMessageError('内容は、必須入力です。');
            flgErr = true;
        } else if (value.length < 5) {
            setMessageError('内容は、5文字以上で入力してください。');
            flgErr = true;
        } else {
            setMessageError('');
        }

        return flgErr;
    }


    /**
     * head要素の設定
     */ 
    const HeadElemet = () => {
        return (
            <>
                <Helmet>
                    <title>お問い合わせ | nijico</title>
                    <meta name="description" content="nijicoへのお問い合わせは、TELかお問い合わせフォームからお待ちしております。" />
                </Helmet>
            </>
        );
    }

    /**
     * 画面表示用の改行コード変換処理
     */ 
    const nl2br = (text) => {
        var regex = /(\n)/g
        return text.split(regex).map(function (line) {
            if (line.match(regex)) {
                return React.createElement('br')
            }
            else {
                return line;
            }
        });
    }

    /**
     * 完了画面を表示させる
     */ 
    if (formSubmitted) {
        return (
            <>
                <HeadElemet />
                <ScrollTopTran />
                <Header />
                <main>
                    <section id="contact_visual">
                        <h2>お問い合わせ</h2>
                    </section>
                    <section id="panlist" class="bg-white">
                        <div class="container">
                            <ul>
                                <li class="home"><a href="<?php bloginfo('url'); ?>/">ホーム</a>&nbsp;</li>
                                <li>＞&nbsp;お問い合わせ</li>
                            </ul>
                        </div>
                    </section>
                    <section id="thanks">
                        <div class="container">
                            <p>お問い合わせ、<span class="text">ありがとうございました。</span></p>
                            <div><Link to="/" onClick={handleReset}>トップへ戻る</Link></div>
                        </div>
                    </section>
                    <CmnContactArea />
                    <ScrollTopIcon />
                </main>
                <Footer />
            </>
        );
    }

    /**
     * 確認画面を表示させる
     */ 
    if (showConfirmation) {
        return (
            <>
                <HeadElemet />
                <ScrollTopTran />
                <Header />
                <main>
                    <section id="contact_visual">
                        <h2>お問い合わせ</h2>
                    </section>
                    <section id="panlist">
                        <div className="container">
                            <ul>
                                <li className="home"><a href="/">ホーム</a>&nbsp;</li>
                                <li>＞&nbsp;お問い合わせ</li>
                            </ul>
                        </div>
                    </section>
                    <section id="confirm_form">
                        <div className="container">
                            <h2>確認フォーム</h2>
                            <p className="explanation">下記の内容でよろしければ、<span className="text">「送信」ボタンを押してください。</span></p>
                    
                            <div className="table">
                                <div className="t_row">
                                    <div className="c_head">お名前</div>
                                    <div className="c_data tsuku-medium">{name}</div>
                                </div>
                                <div className="t_row">
                                    <div className="c_head">ふりがな</div>
                                    <div className="c_data tsuku-medium">{furigana}</div>
                                </div>
                                <div className="t_row">
                                    <div className="c_head">メール<span className="text">アドレス</span></div>
                                    <div className="c_data tsuku-medium">{email}</div>
                                </div>
                                <div className="t_row">
                                    <div className="c_head">電話番号</div>
                                    <div className="c_data tsuku-medium">{tel}</div>
                                </div>
                                <div className="t_row">
                                    <div className="c_head">お問い合わせ<span className="text">内容</span></div>
                                    <div className="c_data tsuku-medium">{nl2br(message)}</div>
                                </div>
                            </div>
                            <div className="btn_form">
                                <button className="btn_send" type="submit" onClick={handleSubmit}>送信</button>
                                <button className="btn_back" type="submit" onClick={handleBack}>戻る</button>
                            </div>
                        </div>
                    </section>
                    <CmnContactArea />
                    <ScrollTopIcon />
                </main>
                <Footer />
            </>
        );
    }

    return (
        <>
            <HeadElemet />
            <ScrollTopTran />
            <Header />
            <main>
                <section id="contact_visual">
                    <h2>お問い合わせ</h2>
                </section>
                <section id="panlist">
                    <div className="container">
                        <ul>
                            <li className="home"><a href="/">ホーム</a>&nbsp;</li>
                            <li>＞&nbsp;お問い合わせ</li>
                        </ul>
                    </div>
                </section>
                <section id="input_form">
                    <div className="container">
                        <h3>お問い合わせ<span className="text">フォーム</span></h3>
                        <p className="explanation">下記のフォームに必要事項を入力後、<span className="text">「確認する」ボタンを</span><span className="text">押してください。</span></p>
                        <form onSubmit={handleConfirm}>
                            <div className="box">
                                <div className="label">
                                    <p>お名前</p>
                                    <span className="required">必須</span>
                                </div>
                                <div className="input_text">
                                    <input type="text" name="namefull" placeholder="お名前" maxLength='15' value={name} onChange={(e) => setName(e.target.value)} onBlur={(e) => handleBlurName(e.target.value)} />
                                    {nameError && <div className="error">{nameError}</div>}
                                </div>
                            </div>
                            <div className="box">
                                <div className="label">
                                    <p>ふりがな</p>
                                    <span className="required">必須</span>
                                </div>
                                <div className="input_text">
                                    <input type="text" name="furigana" placeholder="ふりがな" maxLength='15' value={furigana} onChange={(e) => setFurigana(e.target.value)} onBlur={(e) => handleBlurFurigana(e.target.value)} />
                                    {furiganaError && <div className="error">{furiganaError}</div>}
                                </div>
                            </div>
                            <div className="box">
                                <div className="label">
                                    <p>メールアドレス</p>
                                    <span className="required">必須</span>
                                </div>
                                <div className="input_text">
                                    <input type="email" name="email" placeholder="メールアドレス" maxLength='60' value={email} onChange={(e) => setEmail(e.target.value)} onBlur={(e) => handleBlurEmail(e.target.value)} />
                                    {emailError && <div className="error">{emailError}</div>}
                                </div>
                            </div>
                            <div className="box">
                                <div className="label">
                                    <p>電話番号</p>
                                    <span className="required">必須</span>
                                </div>
                                <div className="input_text">
                                    <input type="tel" name="phonenumber" placeholder="電話番号（ハイフンなし）" maxLength="14" value={tel} onChange={(e) => setTel(e.target.value)} onBlur={(e) => handleBlurTel(e.target.value)} />
                                    {telError && <div className="error">{telError}</div>}
                                </div>
                            </div>
                            <div className="box is-contact">
                                <div className="label">
                                    <p>お問い合わせ内容</p>
                                    <span className="required">必須</span>
                                </div>
                                <div className="input_text">
                                    <textarea name="inquiry" cols="50" rows="10" placeholder="お問い合わせ内容" maxLength="1000" value={message} onChange={(e) => setMessage(e.target.value)} onBlur={(e) => handleBlurMessage(e.target.value)} />
                                    {messageError && <div className='error'>{messageError}</div>}
                                </div>
                            </div>
                            <button type="submit">確認する</button>
                        </form>
                    </div>
                </section>
                <CmnContactArea />
                <ScrollTopIcon />
            </main>
            <Footer />
        </>
    )
}

export default Contact