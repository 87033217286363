import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import CmnContactArea from './CmnContactArea'
import Modal from './Modal'
import ScrollTopIcon from './ScrollTopIcon'
import Loading from './Loading'
import ScrollTopTran from './ScrollTopTran'
import Header from './Header'
import Footer from './Footer'

const Recruit = () => {
    const [show, setShow] = useState(false);
    const [data, setData] = useState([]);

    /**
     * モーダルの開閉制御
     */ 
    const handleOpenModal = (e, type) => {
        e.preventDefault();

        if (type === "type1") {
            setData({
                recruitmentType: '児童発達管理責任者',
                recruitmentForm: '正社員',
                salary: '<p>サービス提供責任者：月給300,000円～400,000円</p><p>その他介護スタッフ：月給260,000円～400,000円</p>',
                jobDescription: '発達に遅れのある子ども達を「療育」を通じで支援していただきます。プログラムはスタッフ皆で話し合いながら決めていきます。また、車での送迎も行っておりますので、運転の出来る方は大歓迎です。',
                trial: '<p>試用期間あり （６か月）<p><p>雇用条件は本採用時と同じ</p>',
                workLocation: '<p>株式会社kind</p><p>神奈川県 藤沢市辻堂2-7-16</p>',
                access: 'JR辻堂駅南口 徒歩３０秒',
                qualification: '児童発達管理責任者',
                workingHours: '<p>火曜日～土曜日 9:30～18:30</p><p>(定休日: 日/月)</p>',
                afterFlow: 'ご応募いただいた後、原則２営業日以内にご連絡させていただきます。',
            });
        } else if (type === "type2") {
            setData({
                recruitmentType: '理学療法士/作業療法士/言語聴覚士',
                recruitmentForm: '正社員、パート',
                salary: '<p>契約社員：月給220,000円～300,000円</p><p>パート：時給1,100円</p>',
                jobDescription: '発達に遅れのある子ども達を「療育」を通じで支援していただきます。プログラムはスタッフ皆で話し合いながら決めていきます。また、車での送迎も行っておりますので、運転の出来る方は大歓迎です。',
                trial: '<p>試用期間あり （６か月）</p><p>雇用条件は本採用時と同じ</p>',
                workLocation: '<p>株式会社kind</p><p>神奈川県 藤沢市辻堂2-7-16</p>',
                access: 'JR辻堂駅南口 徒歩３０秒',
                qualification: '理学療法士/作業療法士/言語聴覚士をお持ちの方',
                workingHours: '<p>火曜日～土曜日 9:30～18:30</p><p>(定休日: 日/月)</p>',
                afterFlow: 'ご応募いただいた後、原則２営業日以内にご連絡させていただきます。',
            });
        } else if (type === "type3") {
            setData({
                recruitmentType: '児童指導員',
                recruitmentForm: '正社員、契約社員、パート',
                salary: '<p>正社員：月給220,000円～300,000円</p><p>契約社員：月給220,000円～300,000円</p><p>パート：時給1,100円</p><p>※能力・経験等を考慮の上、優遇</p>',
                jobDescription: '発達に遅れのある子ども達を「療育」を通じで支援していただきます。プログラムはスタッフ皆で話し合いながら決めていきます。また、車での送迎も行っておりますので、運転の出来る方は大歓迎です。',
                trial: '<p>試用期間あり （６か月）</p><p>雇用条件は本採用時と同じ</p>',
                workLocation: '<p>株式会社kind</p><p>神奈川県 藤沢市辻堂2-7-16</p>',
                access: 'JR辻堂駅南口 徒歩３０秒',
                qualification: '<p>・児童指導員任用資格</p><p>・保育士</p><p>・幼稚園教諭</p><p>・教員免許（小中高）</p><p>・理学療法士</p><p>・言語聴覚士</p><p>・心理士</p><p>上記の資格保有者か</p><p>・４年制大学の「社会学部」「福祉学部」「心理学部」卒業の方</p>',
                workingHours: '<p>火曜日～土曜日 9:30～18:30</p><p>(定休日: 日/月)</p>',
                afterFlow: 'ご応募いただいた後、原則２営業日以内にご連絡させていただきます。',
            });
        } else if (type === "type4") {
            setData({
                recruitmentType: '保育士・幼稚園教諭',
                recruitmentForm: '契約社員、パート',
                salary: '<p>契約社員：月給220,000円～300,000円</p><p>パート：時給1,100円</p>',
                jobDescription: '発達に遅れのある子ども達を「療育」を通じで支援していただきます。プログラムはスタッフ皆で話し合いながら決めていきます。また、車での送迎も行っておりますので、運転の出来る方は大歓迎です。',
                trial: '<p>試用期間あり （６か月）</p><p>雇用条件は本採用時と同じ</p>',
                workLocation: '<p>株式会社kind</p><p>神奈川県 藤沢市辻堂2-7-16</p>',
                access: 'JR辻堂駅南口 徒歩３０秒',
                qualification: '保育士 / 幼稚園教諭をお持ちの方',
                workingHours: '<p>火曜日～土曜日 9:30～18:30</p><p>(定休日: 日/月)</p>',
                afterFlow: 'ご応募いただいた後、原則２営業日以内にご連絡させていただきます。',
            });
        }

        setShow(true);
    }

    return (
        <>
            <Helmet>
                <title>採用情報 | nijico</title>
                <meta name="description" content="nijicoの採用情報を公開しております。ご応募の際は、TELかお問い合わせフォームからご連絡ください。" />
            </Helmet>
            <ScrollTopTran />
            <Loading />
            <Header />
            <main>
                <section id="recruit_visual">
                    <h1>採用情報</h1>
                </section>
                <section id="panlist">
                    <div className="container">
                        <ul>
                            <li className="home"><Link to="/">ホーム</Link>&nbsp;</li>
                            <li>＞&nbsp;採用情報</li>
                        </ul>
                    </div>
                </section>
                <section id="recruit_info">
                    <div className="container">
                        <div className="title">
                            <h2>募集職種</h2>
                            <div className="explanation tsuku-medium">
                                <p>nijicoでは、<span className='text'>以下の職種の方を募集しております！</span></p>
                                <p>募集要項をご確認の上、<span className='text'>ご応募お待ちしております。</span></p>
                                <p>※以下の職種を選択して頂くと、<span className='text'>詳細な情報が表示されます。</span></p>
                            </div>
                        </div>
                        <div className="contents">
                            <div className="recruit" onClick={(e) => handleOpenModal(e, "type1")}>
                                <figure>
                                    <img src={"/img/img_recruit_default_thumb.jpg"} alt="児童発達管理責任者" />
                                    <span>もっと見る<i className="fa-solid fa-circle-right"></i></span>
                                </figure>
                                <div className="recruit-name">児童発達管理責任者</div>
                            </div>
                            <div className="recruit" onClick={(e) => handleOpenModal(e, "type3")}>
                                <figure>
                                    <img src={"/img/img_recruit_default_thumb.jpg"} alt="児童指導員" />
                                    <span>もっと見る<i className="fa-solid fa-circle-right"></i></span>
                                </figure>
                                <div className="recruit-name">児童指導員</div>
                            </div>
                            <div className="recruit" onClick={(e) => handleOpenModal(e, "type2")}>
                                <figure>
                                    <img src={"/img/img_recruit_default_thumb.jpg"} alt="理学療法士/作業療法士/言語聴覚士" />
                                    <span>もっと見る<i className="fa-solid fa-circle-right"></i></span>
                                </figure>
                                <div className="recruit-name">理学療法士/作業療法士/言語聴覚士</div>
                            </div>
                            <div className="recruit" onClick={(e) => handleOpenModal(e, "type4")}>
                                <figure>
                                    <img src={"/img/img_recruit_default_thumb.jpg"} alt="保育士/幼稚園教諭" />
                                    <span>もっと見る<i className="fa-solid fa-circle-right"></i></span>
                                </figure>
                                <div className="recruit-name">保育士/幼稚園教諭</div>
                            </div>
                        </div>
                    </div>
                </section>
                <CmnContactArea />
                <Modal show={show} setShow={setShow} data={data} />
                <ScrollTopIcon />
            </main>
            <Footer />
        </>
    )
}

export default Recruit