import React from 'react'
import { Link } from 'react-router-dom';
import { queryStringTimestamp } from './Utils';
import { Helmet } from 'react-helmet-async';
import ScrollTopIcon from './ScrollTopIcon';
import Loading from './Loading';
import CmnContactArea from './CmnContactArea';
import ScrollTopTran from './ScrollTopTran';
import Header from './Header';
import Footer from './Footer';

const Attempt = () => {
    return (
        <>
            <Helmet>
                <title>とりくみ | nijico</title>
                <meta name="description" content="nijicoが力を入れている自慢のプログラムの内容を記載しております。" />
            </Helmet>
            <ScrollTopTran />
            <Loading />
            <Header />
            <main>
                <section id="attemptVisual">
                    <h1>とりくみ</h1>
                </section>

                <section id="panlist" className="p_attempt">
                    <div className="container">
                        <ul>
                            <li className="home"><Link to="/">ホーム</Link>&nbsp;</li>
                            <li>＞&nbsp;とりくみ</li>
                        </ul>
                    </div>
                </section>

                <section id="attempt">
                    <div className="title">
                        <h2>
                            <span className="number">5</span>つ<span className="strSmall">の</span><br />｢とりくみ｣
                        </h2>
                        <p className="tsuku-medium">nijico自慢のサポートプログラムを<span className="text">ご紹介します。</span></p>
                    </div>
                    <div className="elements">
                        <div id="learningSupport">
                            <div className="container">
                                <div className="titlePart">
                                    <div className="grayBg"></div>
                                    <div className="wrap">
                                        <div className="innerTitle">
                                            <span className="attemptNumber">01</span>
                                            <h3>学習支援</h3>
                                        </div>
                                        <div className="innerImg">
                                            <figure><img src={"/img/img_attempt_01.jpg" + queryStringTimestamp()} alt="学習支援" /></figure>
                                        </div>
                                    </div>
                                </div>
                                <div className="textPart tsuku-medium">
                                    <p>基本的に学校の宿題のサポートを行います。わからない所があれば、職員と一緒に考え取り組めるようサポートいたします。宿題のないお子様には、教室で提供する課題等を取り組んでもらいます。学習の基礎土台となる「座る」「鉛筆の持ち方」から「学習のサポート」までを行い、学習習慣の定着を目指していきます。</p>
                                </div>
                            </div>
                        </div>
                        <div id="individualSupport">
                            <div className="container">
                                <div className="titlePart">
                                    <div className="grayBg"></div>
                                    <div className="wrap">
                                        <div className="innerTitle">
                                            <span className="attemptNumber">02</span>
                                            <h3>個別支援</h3>
                                        </div>
                                        <div className="innerImg">
                                            <figure><img src={"/img/img_attempt_02.jpg" + queryStringTimestamp()} alt="個別支援" /></figure>
                                        </div>
                                    </div>
                                </div>
                                <div className="textPart tsuku-medium">
                                    <p>個別支援計画をもとに、お子様の発達状況・特性や保護者様の要望に応じて、お子様に合わせた個別活動を作成していきます。その上でお子様の無理なく少しずつできるようになっていくことだけでなく、できるようになっていく中で一つの課題に向き合い取り組むことができるようサポートし、お子様の身辺自立を目指していきます。</p>
                                </div>
                            </div>
                        </div>
                        <div id="group">
                            <div className="container">
                                <div className="titlePart">
                                    <div className="grayBg"></div>
                                    <div className="wrap">
                                        <div className="innerTitle">
                                            <span className="attemptNumber">03</span>
                                            <h3>集団活動<br /><span className="strSubSmall">(プログラム)</span></h3>
                                        </div>
                                        <div className="innerImg">
                                            <figure><img src={"/img/img_attempt_03.jpg" + queryStringTimestamp()} alt="集団活動(プログラム)" /></figure>
                                        </div>
                                    </div>
                                </div>
                                <div className="textPart tsuku-medium">
                                    <p>集団活動に参加していく中で、「活動内でのルールの理解」や「お友達との関係構築や対人スキル」「感情コントロール」の習得を目指していきます。初めて取り組むことに対してチャレンジする気持ちを養っていけるよう、お子様が楽しく参加できるような活動を行っていきます。</p>
                                </div>
                            </div>
                        </div>
                        <div id="leisure">
                            <div className="container">
                                <div className="titlePart">
                                    <div className="grayBg"></div>
                                    <div className="wrap">
                                        <div className="innerTitle">
                                            <span className="attemptNumber">04</span>
                                            <h3>余暇活動<br /><span className="strSubSmall">(自由時間)</span></h3>
                                        </div>
                                        <div className="innerImg">
                                            <figure><img src={"/img/img_attempt_04.jpg" + queryStringTimestamp()} alt="余暇活動(自由時間)" /></figure>
                                        </div>
                                    </div>
                                </div>
                                <div className="textPart tsuku-medium">
                                    <p>お子様一人ひとりが好きな物で遊べるよう、色々なおもちゃを準備しております。余暇時間を過ごしていく中で「お友達との関わり方」「物を大切にすること」「遊んだ後はお片付けをすること」などがお子様にとって日々の習慣となっていけるよう、伝えていきます。<br />お子様にとって自由時間はとても大切な時間と考えます。ゆっくり過ごしたいお子様・沢山遊びたいお子様それぞれが余暇時間を充実して過ごせるようサポートいたします。</p>
                                </div>
                            </div>
                        </div>
                        <div id="goingOut">
                            <div className="container">
                                <div className="titlePart">
                                    <div className="grayBg"></div>
                                    <div className="wrap">
                                        <div className="innerTitle">
                                            <span className="attemptNumber">05</span>
                                            <h3>外出支援</h3>
                                        </div>
                                        <div className="innerImg">
                                            <figure><img src={"/img/img_attempt_05.jpg" + queryStringTimestamp()} alt="外出支援" /></figure>
                                        </div>
                                    </div>
                                </div>
                                <div className="textPart tsuku-medium">
                                    <p>祝日や長期休みなど、お外に出られる機会があれば外出支援を行います。お出掛けをする際は、楽しく安全に行えるよう、お出掛けをするときのお約束を守りながら、動物園や博物館などへ行ったり、公園ではみんなと一緒に遊んだりと、教室ではできないような経験をします。</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <CmnContactArea />
                <ScrollTopIcon />
            </main>
            <Footer />
        </>
    )
}

export default Attempt