import React from 'react'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async'
import CmnContactArea from './CmnContactArea'
import ScrollTopIcon from './ScrollTopIcon'
import Loading from './Loading'
import ScrollTopTran from './ScrollTopTran';
import Header from './Header';
import Footer from './Footer';

const About = () => {
    return (
        <>
            <Helmet>
                <title>発達支援・放デイについて | nijico</title>
                <meta name="description" content="発達支援・放デイの概要を記載しております。" />
            </Helmet>
            <ScrollTopTran />
            <Loading />
            <Header />
            <main>
                <section id="about_visual">
                    <h1>発達支援・放デイ<span className="text">について</span></h1>
                </section>
                <section id="panlist" className="bg-white">
                    <div className="container">
                        <ul>
                            <li className="home"><Link to="/">ホーム</Link>&nbsp;</li>
                            <li>＞&nbsp;発達支援・放デイについて</li>
                        </ul>
                    </div>
                </section>
                <section id="about_support">
                    <div className="container">
                        <div className="about_support1">
                            <h2>児童発達支援と放課後デイサービスについて </h2>
                            <p className="tsuku-medium">平成24年４月から、障害者自立支援法に基づく「児童デイサービス」から、 児童福祉法に基づく「児童発達支援」「放課後等デイサービス」の２つに分かれました。
                            </p>
                            <h3>◆発達支援とは？</h3>
                            <p className="tsuku-medium">
                                0歳～6歳までのお子様の心身の発達に遅れや、つまづきの感じられる未就学児のお子さま達に発達の支援をします。幼少期から集団生活の楽しみや人との関わりを感じ成長や発達を促進致します。</p>
                            <h3>◆放課後等デイサービスとは？</h3>
                            <p className="tsuku-medium">主に小学生から高校生までの学校に通っている療養の必要な子供たちが下校後や、休校日等や、
                                夏休み、冬休み等の長期休暇に利用する生活能力向上のための訓練および社会との交流促進等を継続的に提供する場所です。</p>
                        </div>
                        <div className="about_support2">
                            <h2>障害児相談支援について</h2>
                            <p className="tsuku-medium">
                                障害児が障害児通所支援（児童発達支援・放課後等デイサービスなど）を利用する前に障害児支援利用計画を作成し（障害児支援利用援助）、通所支援開始後、一定期間ごとにモニタリングを行う（継続障害児支援利用援助）等の支援を行います。
                                サービスを効果的に使うためにマネージメントを行うサービスです。</p>
                            <h3>◆障害児支援利用援助とは？</h3>
                            <p className="tsuku-medium">
                                障害児通所支援の利用申請手続きにおいて、障害児の心身の状況や環境、障害児または保護者の意向などを踏まえて「障害児支援利用計画案」の作成を行います。利用が決定した際は、サービス事業者等との連絡調整、決定内容に基づく「障害児支援利用計画」の作成を行います。
                            </p>
                            <h3>◆継続障害児支援利用援助とは？</h3>
                            <p className="tsuku-medium">
                                利用している障害児通所支援について、その内容が適切かどうか一定期間ごとにサービス等の利用状況の検証を行い、「障害児支援利用計画」の見直しを行います（モニタリング）。また、モニタリングの結果に基づき、計画の変更申請などを勧奨します。

                            </p>
                        </div>
                    </div>
                </section>
                <CmnContactArea />
                <ScrollTopIcon />
            </main>
            <Footer />
        </>
    )
}

export default About