import React, { useState, useEffect } from 'react';

const ScrollTopIcon = () => {
    const [isDisplay, setIsDisplay] = useState(false);

    /**
     * クリックすると、ページのトップへ移動する
     */ 
    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }

    /**
     * 80pxスクロールした位置でスクロールトップボタンを表示する
     */ 
    const handleOnScroll = () => {
        const position = scrollPosition();
        if (position >= 80) {
            setIsDisplay(true);
        } else {
            setIsDisplay(false);
        }
    };

    /**
     * スクロールした位置を算出する
     */ 
    const scrollPosition = () => {
        return Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
    };

    /**
     * スクロールイベントを付与
     */ 
    useEffect(() => {
        document.addEventListener("scroll", handleOnScroll);
    });

    return (
        <>
            <div id="scroll-top"  className={isDisplay ? "is-active" : ""} onClick={() => scrollTop()} >
                <i className="fa-solid fa-arrow-up"></i>
            </div>
        </>
    )
}

export default ScrollTopIcon