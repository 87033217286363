import React, {useState, useEffect} from 'react'
import { queryStringTimestamp } from './Utils';
import { Link, useLocation } from 'react-router-dom';

const Header = () => {
    const pathname = useLocation().pathname;
    const [active, setActive] = useState(false);
    const [childMenuActive, setChildMenuActive] = useState(false);

    useEffect(() => {
        setActive(false);
        setChildMenuActive(false);
    }, [pathname]);

    const handleMenuClick = (e) => {
        e.preventDefault();

        setActive(!active)
    }

    const handleOpenClassroom = (e) => {
        e.preventDefault();

        setChildMenuActive(!childMenuActive);
    }

    return (
        <>
            <header>
            <div className="container">
                <div className="headC">
                    <div className={`menu ${active ? "is-active" : ""}`} onClick={(e) => handleMenuClick(e)}><span></span></div>
                </div>
                <div className="headA">
                    {pathname === (`/`) &&
                        <h1>
                            <Link to="/"><img src={"/img/img_header_logo_sp.png" + queryStringTimestamp()} alt="nijico | 児童発達支援・放課後等デイサービス" /></Link>
                        </h1>
                    }
                    {pathname !== (`/`) &&
                        <div className="head-logo">
                            <Link to="/"><img src={"/img/img_header_logo_sp.png" + queryStringTimestamp()} alt="nijico | 児童発達支援・放課後等デイサービス" /></Link>
                        </div>
                    }
                </div>
                <div className="headB">
                    <nav className="nav_pc">
                        <ul className="nav-lists">
                            <li>
                                <Link className="parent-menu" to="/" onClick={ (e) => e.preventDefault() }>
                                    <img src={"/img/img_header_classroom.png" + queryStringTimestamp()} alt="ご利用ガイド" />
                                    <div>教室情報<i className="fa-solid fa-caret-down"></i></div>
                                </Link>
                                <ul className="child-menu">
                                    <li>
                                        <Link to="/classroom/classroom1">
                                            <p className="child-jp">nijico辻堂駅前</p>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/classroom/classroom2">
                                            <p className="child-jp">nijico湘南台</p>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <Link to="/guide">
                                    <img src={"/img/img_header_guide.png" + queryStringTimestamp()} alt="ご利用ガイド" />
                                    <div>ご利用ガイド</div>
                                </Link>
                            </li>
                            <li>
                                <Link to="/attempt">
                                    <img src={"/img/img_header_attempt.png" + queryStringTimestamp()} alt="とりくみ" />
                                    <div>とりくみ</div>
                                </Link>
                            </li>
                            <li>
                                <Link to="/recruit">
                                    <img src={"/img/img_header_recruit.png" + queryStringTimestamp()} alt="採用情報" />
                                    <div>採用情報</div>
                                </Link>
                            </li>
                            <li>
                                <Link to="/company">
                                    <img src={"/img/img_header_profile.png" + queryStringTimestamp()} alt="会社概要" />
                                    <div>施設概要</div>
                                </Link>
                            </li>
                            <li>
                                <Link to="/contact">
                                    <img src={"/img/img_header_contact.png" + queryStringTimestamp()} alt="お問い合わせ" />
                                    <div>お問い合わせ</div>
                                </Link>
                            </li>
                        </ul>
                    </nav>
                    <nav className={`nav_sp ${active ? "is-active" : ""}`}>
                        <ul className="nav-lists">
                            <li><Link to="/">トップ</Link></li>
                            <li>
                                <Link className="parent-menu" to="/" onClick={ (e) => handleOpenClassroom(e) }>
                                    教室情報<i className="fa-solid fa-caret-down"></i>
                                </Link>
                                <ul className={`child-menu ${childMenuActive ? "is-active" : ""}`}>
                                    <li>
                                        <Link to="/classroom/classroom1">
                                            <p className="child-jp">nijico辻堂駅前</p>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/classroom/classroom2">
                                            <p className="child-jp">nijico湘南台</p>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li><Link to="/guide">ご利用ガイド</Link></li>
                            <li><Link to="/attempt">とりくみ</Link></li>
                            <li><Link to="/recruit">採用情報</Link></li>
                            <li><Link to="/company">施設概要</Link></li>
                            <li><Link to="/contact">お問い合わせ</Link></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </header>
        </>
    )
}

export default Header