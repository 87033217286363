import React from 'react'
import parse from 'html-react-parser';

const Modal = ({show, setShow, data}) => {
    const closeModal = () => {
        setShow(false);
    };

    if (show) {
        return (
            <>
                <div id="modal" className={ show ? 'is-active' : '' }>
                    <div className="bg" onClick={() => closeModal()}></div>
                    <div className="wrapper">
                        <h2>募集詳細</h2>
                        <div className="tbl">
                            <div className="t-row">
                                <div className="t-head">職種</div>
                                <div className="t-data recruitment-type">{data.recruitmentType}</div>
                            </div>
                            <div className="t-row">
                                <div className="t-head">雇用形態</div>
                                <div className="t-data recruitment-form">{data.recruitmentForm}</div>
                            </div>
                            <div className="t-row">
                                <div className="t-head">給与</div>
                                <div className="t-data salary">{parse(data.salary)}</div>
                            </div>
                            <div className="t-row">
                                <div className="t-head">仕事内容</div>
                                <div className="t-data job-description">{data.jobDescription}</div>
                            </div>
                            <div className="t-row">
                                <div className="t-head">試用・研修</div>
                                <div className="t-data trial">{parse(data.trial)}</div>
                            </div>
                            <div className="t-row">
                                <div className="t-head">勤務地</div>
                                <div className="t-data work-location">{parse(data.workLocation)}</div>
                            </div>
                            <div className="t-row">
                                <div className="t-head">アクセス</div>
                                <div className="t-data access">{data.access}</div>
                            </div>
                            <div className="t-row">
                                <div className="t-head">応募資格</div>
                                <div className="t-data qualification">{parse(data.qualification)}</div>
                            </div>
                            <div className="t-row">
                                <div className="t-head">勤務時間</div>
                                <div className="t-data working-hours">{parse(data.workingHours)}</div>
                            </div>
                            <div className="t-row">
                                <div className="t-head">応募後の流れ</div>
                                <div className="t-data after-flow">{data.afterFlow}</div>
                            </div>
                        </div>
                    </div>
                    <div className='btn-close' onClick={() => closeModal()}></div>
                </div >
            </>
        )
    } else {
        return null;
    }
}

export default Modal