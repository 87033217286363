import React from 'react'
import { Helmet } from 'react-helmet-async'

const NotFound = () => {
    return (
        <>
            <Helmet>
                <title>ページが存在しません | nijico</title>
                <meta name="description" content="ページが存在しません。URLを見直してください。" />
            </Helmet>
            <div>ページが存在しません。URLを見直してください。</div>
        </>
    )
}

export default NotFound