import React, { useLayoutEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { gsap } from "gsap";
import {useWindowSize} from 'react-use';

const Loading = () => {
    const pathname = useLocation().pathname;
    const loadingRef = useRef(null);
    const { height } = useWindowSize();
    const [isInitial, setIsInitial] = useState(false);

    useLayoutEffect(() => {
        // 初期表示時のみ
        if (!isInitial) {
            loadingRef.current.style.height = `${height}px`;

            const TL = gsap.timeline();
            gsap.set("#loading", {autoAlpha: 1});
            gsap.set("#logo2", {autoAlpha: 0});
            gsap.set("#logo #pink", {autoAlpha: 0});
            gsap.set("#logo #purple", {autoAlpha: 0});
            gsap.set("#logo #blue", {autoAlpha: 0});
            gsap.set("#logo #green", {autoAlpha: 0});
            gsap.set("#logo #yellow", {autoAlpha: 0});
            gsap.set("#logo #orange", {autoAlpha: 0});
            gsap.set("#logo #red", {autoAlpha: 0});

            if (pathname === "/") {
                TL
                .to("#logo #pink", 0.3, {autoAlpha: 1})
                .to("#logo #purple", 0.3, {autoAlpha: 1}, "-=0.3")
                .to("#logo #blue", 0.3, {autoAlpha: 1})
                .to("#logo #green", 0.3, {autoAlpha: 1}, "-=0.3")
                .to("#logo #yellow", 0.3, {autoAlpha: 1})
                .to("#logo #orange", 0.3, {autoAlpha: 1}, "-=0.3")
                .to("#logo #red", 0.3, {autoAlpha: 1})
                .to("#logo2", 1.5, {autoAlpha: 1})
                .to("#loading", 1, {autoAlpha: 0})
            } else {
                TL
                .to("#logo #pink", 0.2, {autoAlpha: 1})
                .to("#logo #purple", 0.2, {autoAlpha: 1}, "-=0.2")
                .to("#logo #blue", 0.2, {autoAlpha: 1})
                .to("#logo #green", 0.2, {autoAlpha: 1}, "-=0.2")
                .to("#logo #yellow", 0.2, {autoAlpha: 1})
                .to("#logo #orange", 0.2, {autoAlpha: 1}, "-=0.2")
                .to("#logo #red", 0.2, {autoAlpha: 1})
                .to("#logo2", 1.5, {autoAlpha: 1})
                .to("#loading", 1, {autoAlpha: 0})
            }

            setIsInitial(true);
        }
    }, [pathname, height, isInitial]);

    return (
        <>
            <div id="loading" ref={loadingRef}>
                <div className="wrap">
                    <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 124.76 62.69">
                        <path id='red' className="cls-3"
                            d="m62.6,0C29.25-.13,1.9,25.91,0,58.82c-.11,1.86,1.38,3.42,3.24,3.43l4.01.02c.11-30.44,24.89-55.03,55.33-54.91,30.44.11,55.03,24.88,54.91,55.33h4.01c1.86.02,3.36-1.53,3.26-3.39C123.11,26.37,95.96.13,62.6,0Z" />
                        <path id='orange' className="cls-1"
                            d="m62.58,7.35c-30.44-.11-55.21,24.47-55.33,54.91l7.35.03c.1-26.38,21.57-47.69,47.95-47.59,26.38.1,47.69,21.57,47.59,47.95l7.35.03c.11-30.44-24.47-55.21-54.91-55.33Z" />
                        <path id='yellow' className="cls-4"
                            d="m62.55,14.7c-26.38-.1-47.85,21.21-47.95,47.59l7.35.03c.08-22.32,18.25-40.35,40.57-40.27,22.32.08,40.35,18.25,40.27,40.57l7.35.03c.1-26.38-21.21-47.85-47.59-47.95Z" />
                        <path id='green' className="cls-7"
                            d="m62.52,22.05c-22.32-.08-40.49,17.94-40.57,40.27l7.35.03c.07-18.26,14.93-33.02,33.2-32.95,18.26.07,33.02,14.93,32.95,33.2l7.35.03c.08-22.32-17.94-40.49-40.27-40.57Z" />
                        <path id='blue' className="cls-6"
                            d="m62.49,29.4c-18.26-.07-33.13,14.68-33.2,32.95l7.35.03c.05-14.21,11.61-25.68,25.82-25.62,14.21.05,25.68,11.61,25.62,25.82l7.35.03c.07-18.26-14.68-33.13-32.95-33.2Z" />
                        <path id='purple' className="cls-5"
                            d="m62.47,36.75c-14.21-.05-25.77,11.42-25.82,25.62l7.35.03c.04-10.15,8.29-18.34,18.44-18.3,10.15.04,18.34,8.3,18.3,18.44l7.35.03c.05-14.21-11.42-25.77-25.62-25.82Z" />
                        <path id="pink" className="cls-2"
                            d="m62.44,44.09c-10.15-.04-18.4,8.16-18.44,18.3l4.48.02c1.54,0,2.82-1.09,3.19-2.59,1.19-4.83,5.55-8.4,10.75-8.39,5.19.02,9.53,3.63,10.68,8.47.36,1.5,1.63,2.6,3.17,2.61l4.48.02c.04-10.15-8.16-18.4-18.3-18.44Z" />
                    </svg>
                    <svg id="logo2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 169.71 47.68">
                        <path className="cls-1"
                            d="m20.41,15.56c-2.26-1.99-5.3-3.08-8.54-3.07C5.26,12.53.09,17.52.06,23.3L0,38.49c0,.56.51,1.01,1.15,1.01l5.01.02c.64,0,1.16-.45,1.16-1l.06-15.65c0-1.1.47-2.08,1.34-2.84.87-.76,2-1.15,3.25-1.15s2.4.43,3.24,1.17c.9.8,1.38,1.78,1.37,2.85l-.06,15.65c0,.56.51,1.01,1.15,1.01l5.01.02c.64,0,1.16-.45,1.16-1l.06-15.65c.01-2.76-1.23-5.39-3.49-7.38Z" />
                        <path className="cls-1"
                            d="m45.56,33.29h-.52c-.71,0-.97-.51-.97-1.14l.07-17.04c0-.62-.57-1.13-1.29-1.14l-4.78-.02c-.71,0-1.3.5-1.3,1.13l-.07,17.04c0,.62-.26,1.13-.98,1.13h-.52c-.71,0-1.3.5-1.3,1.12l-.02,4.14c0,.62.57,1.13,1.29,1.14l10.35.04c.71,0,1.3-.5,1.3-1.13l.02-4.14c0-.62-.57-1.13-1.29-1.14Z" />
                        <path className="cls-1"
                            d="m40.46,8.61c2.39,0,4.34-1.9,4.35-4.26S42.88,0,40.49,0c-2.36,0-4.29,1.93-4.3,4.32s1.9,4.29,4.26,4.3Z" />
                        <path className="cls-1"
                            d="m103.21,33.53h-.52c-.71,0-.97-.51-.97-1.14l.07-17.04c0-.62-.57-1.13-1.29-1.14l-4.78-.02c-.71,0-1.3.5-1.3,1.13l-.07,17.04c0,.62-.26,1.13-.98,1.13h-.52c-.71,0-1.3.5-1.3,1.12l-.02,4.14c0,.62.57,1.13,1.29,1.14l10.35.04c.71,0,1.3-.5,1.3-1.13l.02-4.14c0-.62-.57-1.13-1.29-1.14Z" />
                        <path className="cls-1"
                            d="m98.11,8.85c2.39,0,4.34-1.9,4.35-4.26s-1.93-4.34-4.32-4.35c-2.36,0-4.29,1.93-4.3,4.32s1.9,4.29,4.26,4.3Z" />
                        <path className="cls-1"
                            d="m122.46,20.5c.94-.75,2.15-1.15,3.5-1.15,1.32,0,2.59.43,3.49,1.17.55.45.94.96,1.22,1.61,0,0,.28.61,1.04.32.76-.3,5.34-2.5,5.81-2.71.47-.21.14-.69.14-.69-.65-1.11-1.55-2.16-2.62-3.03-2.43-1.99-5.7-3.08-9.19-3.07-7.12.03-12.7,5.02-12.72,10.8l-.03,6.2c-.01,2.74,1.3,5.36,3.7,7.38,2.4,1.96,5.62,3.05,9.06,3.07,3.49.01,6.64-1.02,9.09-2.99,1.14-.92,1.99-1.9,2.65-3.07,0,0,.22-.51-.26-.73-.48-.22-5.28-2.48-5.82-2.72-.54-.24-.9.39-.9.39-.66,1.64-2.57,2.74-4.73,2.73-1.35,0-2.56-.41-3.48-1.16-.94-.81-1.44-1.8-1.43-2.86l.03-6.66c0-1.1.5-2.08,1.44-2.83Z" />
                        <path className="cls-1"
                            d="m166.22,16.16c-2.26-1.99-5.29-3.08-8.53-3.07-6.61.03-11.79,5.03-11.81,10.8l-.03,6.2c-.01,2.74,1.21,5.36,3.44,7.38,2.23,1.96,5.21,3.05,8.41,3.07,3.26.01,6.25-1.05,8.44-3,2.28-1.98,3.54-4.59,3.55-7.35l.03-6.66c.01-2.76-1.23-5.39-3.49-7.38Zm-3.85,14.01c0,2.24-2.05,3.99-4.65,3.98-1.26,0-2.38-.41-3.23-1.16-.87-.81-1.33-1.8-1.33-2.86l.03-6.66c0-1.1.47-2.08,1.34-2.84.87-.76,2-1.15,3.25-1.15s2.4.43,3.24,1.17c.9.79,1.38,1.78,1.37,2.85l-.03,6.66Z" />
                        <path className="cls-1"
                            d="m72.13,9.44c2.36,0,4.29-1.9,4.3-4.26s-1.9-4.34-4.26-4.35c-2.39,0-4.34,1.93-4.35,4.32s1.89,4.29,4.32,4.3Z" />
                        <path className="cls-1"
                            d="m81.6,27.74l-5.62-.02c-.14,0-.25-.09-.25-.21.02-2.41.06-1.83.1-11.35,0,0-.04-1.29-1.13-1.29l-4.77-.02c-.81,0-1.47.57-1.47,1.28l-.05,11.34c0,.13-.12.23-.26.23h-2.21c-6.28-.03-10.76,4.43-10.78,9.95-.01,2.67.91,5.37,3.06,7.27,2.15,1.9,4.62,2.77,7.64,2.78,3.2.01,5.26-1.15,7.48-3.26,2.01-1.92,2.33-4.5,2.34-7.1v-2.98c.01-.13.13-.23.27-.23l5.58.02c.83,0,1.5-.58,1.5-1.3l.02-3.82c0-.7-.65-1.27-1.45-1.28Zm-16.32,13.34c-1.8,0-3.25-1.47-3.25-3.27s1.47-3.25,3.27-3.25,3.25,1.47,3.25,3.27-1.47,3.25-3.27,3.25Z" />
                    </svg>
                </div>
            </div>
        </>
    )
}

export default Loading