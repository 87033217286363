import React from 'react'
import { Helmet } from 'react-helmet-async'

const Error = () => {
    return (
        <>
            <Helmet>
                <title>システムエラー | nijico</title>
                <meta name="description" content="システム障害が発生しました。管理者にお問い合わせください。" />
            </Helmet>
            <div>システム障害が発生しました。管理者にお問い合わせください。</div>
        </>
    )
}

export default Error