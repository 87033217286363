import React from 'react'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import CmnContactArea from './CmnContactArea';
import ScrollTopIcon from './ScrollTopIcon';
import Loading from './Loading';
import ScrollTopTran from './ScrollTopTran';
import Header from './Header';
import Footer from './Footer';

const Company = () => {
    return (
        <>
            <Helmet>
                <title>施設概要 | nijico</title>
                <meta name="description" content="nijicoの住所やTEL、FAXなどの情報を記載しております。" />
            </Helmet>
            <ScrollTopTran />
            <Loading />
            <Header />
            <main>
                <section id="company_visual">
                    <h1>施設概要</h1>
                </section>
                <section id="panlist">
                    <div className="container">
                        <ul>
                            <li className="home"><Link to="/">ホーム</Link>&nbsp;</li>
                            <li>＞&nbsp;施設概要</li>
                        </ul>
                    </div>
                </section>

                <section id="facility_info">
                    <div className="container">
                        <h2>施設情報</h2>
                        <div className="table">
                            <div className="t_row">
                                <div className="c_head tsuku-bold">施設名</div>
                                <div className="c_data tsuku-medium">児童発達支援・<span className="text">放課後等</span><span
                                        className="text">デイサービス</span><br />nijico</div>
                            </div>
                            <div className="t_row">
                                <div className="c_head tsuku-bold">施設住所</div>
                                <div className="c_data tsuku-medium">
                                    <div className="classroom">
                                        <p className="classroom__label">＜nijico辻堂駅前＞</p>
                                        <p className="classroom__txt">〒251-0047<br />神奈川県藤沢市辻堂2丁目7-16 <span className='text'>SHONAN SAKURAI BUILLDING 3F</span></p>
                                    </div>
                                    <div className="classroom">
                                        <p className="classroom__label">＜nijico湘南台＞</p>
                                        <p className="classroom__txt">〒252-0804<br />神奈川県藤沢市湘南台2-26-18 <span className='text'>サザンヒル1F</span></p>
                                    </div>
                                </div>
                            </div>
                            <div className="t_row">
                                <div className="c_head tsuku-bold">施設電話<span className="text">番号</span></div>
                                <div className="c_data tsuku-medium">
                                    <div className="classroom">
                                        <p className="classroom__label">＜nijico辻堂駅前＞</p>
                                        <p className="classroom__txt">0466-47-6730</p>
                                    </div>
                                    <div className="classroom">
                                        <p className="classroom__label">＜nijico湘南台＞</p>
                                        <p className="classroom__txt">0466-21-9840</p>
                                    </div>
                                </div>
                            </div>
                            <div className="t_row">
                                <div className="c_head tsuku-bold">FAX</div>
                                <div className="c_data tsuku-medium">
                                    <div className="classroom">
                                        <p className="classroom__label">＜nijico辻堂駅前＞</p>
                                        <p className="classroom__txt">0466-47-6740</p>
                                    </div>
                                    <div className="classroom">
                                        <p className="classroom__label">＜nijico湘南台＞</p>
                                        <p className="classroom__txt">0466-21-9841</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <CmnContactArea />
                <ScrollTopIcon />
            </main>
            <Footer />
        </>
    )
}

export default Company