import React from 'react';
import { Link } from 'react-router-dom';
import { queryStringTimestamp } from './Utils';
import CmnContactArea from './CmnContactArea';
import { Helmet } from 'react-helmet-async';
import ScrollTopIcon from './ScrollTopIcon';
import Loading from './Loading';
import ScrollTopTran from './ScrollTopTran';
import Header from './Header';
import Footer from './Footer';

const Classroom1 = () => {
    return (
        <>
            <Helmet>
                <title>教室情報 - nijico辻堂駅前 | nijico</title>
                <meta name="description" content="教室の様子を写真で公開しております。また、TEL・SNSの情報なども記載しております。" />
            </Helmet>
            <ScrollTopTran />
            <Loading />
            <Header />
            <main>
                <section id="classroom-visual">
                    <h2>教室情報</h2>
                </section>
                <section id="panlist" className="bg-white">
                    <div className="container">
                        <ul>
                            <li className="home"><Link to="/">ホーム</Link>&nbsp;</li>
                            <li>＞&nbsp;教室情報</li>
                            <li>＞&nbsp;nijico辻堂駅前</li>
                        </ul>
                    </div>
                </section>
                <section id="classroom-info-dtl">
                    <div className="container">
                        <div className="l-area">
                            <img src={"/img/img_classroom_each_01.jpg" + queryStringTimestamp()} alt="nijico辻堂駅前の外観写真" />
                        </div>
                        <div className="r-area">
                            <div className="classroom__info">
                                <h3>nijico辻堂駅前</h3>
                                <div className="tbl">
                                    <div className="t_row">
                                        <div className="t_lbl">TEL</div>
                                        <div className="t_txt telno">0466-47-6730</div>
                                    </div>
                                    <div className="t_row">
                                        <div className="t_lbl">FAX</div>
                                        <div className="t_txt telno">0466-47-6740</div>
                                    </div>
                                    <div className="t_row">
                                        <div className="t_lbl">住所</div>
                                        <div className="t_txt">
                                            <p>〒251-0047</p>
                                            <p> 神奈川県藤沢市辻堂2-7-16 <br />SHONAN SAKURAI BUILLDING 3F</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="classroom_sns">
                                    <Link className="insta-link" to="https://www.instagram.com/nijico_tsujido/" target="_blank" rel="noopener noreferrer"><img src={"/img/img_classroom_instagram.png" + queryStringTimestamp()} alt="instagram-icon" /></Link>
                                    <Link className="tiktok-link" to="https://www.tiktok.com/@nijico01" target="_blank" rel="noopener noreferrer"><img src={"/img/img_classroom_tiktok.png" + queryStringTimestamp()} alt="line-icon" /></Link>
                                    <Link className="litalico-banner" to="https://h-navi.jp/support_facility/facilities/160567" target="_blank" rel="noopener noreferrer"><img src={"/img/img_litalico_classroom.png" + queryStringTimestamp()} alt="リタリコ" /></Link>
                                </div>
                            </div>
                            <div className="classroom-map">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13019.116270759838!2d139.4462033!3d35.3363074!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60184d8dc64e18e5%3A0x6a5f8092de1b0329!2z5YWQ56ul55m66YGU5pSv5o-044O75pS-6Kqy5b6M562J44OH44Kk44K144O844OT44K544CQbmlqaWNv44CR!5e0!3m2!1sja!2sjp!4v1684115983895!5m2!1sja!2sjp" style={{border:0}} loading="lazy" title='nijico辻堂駅前のMAP'></iframe>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="classroom-gallery">
                    <div className="container">
                        <figure><img src={"/img/img_classroom_each_01-01.jpg" + queryStringTimestamp()} alt="写真01" /></figure>
                        <figure><img src={"/img/img_classroom_each_01-02.jpg" + queryStringTimestamp()} alt="写真02" /></figure>
                        <figure><img src={"/img/img_classroom_each_01-03.jpg" + queryStringTimestamp()} alt="写真03" /></figure>
                        <figure><img src={"/img/img_classroom_each_01-04.jpg" + queryStringTimestamp()} alt="写真04" /></figure>
                        <figure><img src={"/img/img_classroom_each_01-05.jpg" + queryStringTimestamp()} alt="写真05" /></figure>
                        <figure><img src={"/img/img_classroom_each_01-06.jpg" + queryStringTimestamp()} alt="写真06" /></figure>
                    </div>
                </section>
                <CmnContactArea />
                <ScrollTopIcon />
            </main>
            <Footer />
        </>
    )
}

export default Classroom1