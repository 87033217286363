import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { queryStringTimestamp } from './Utils';

const Footer = () => {
    const [year, setYear] = useState('');

    useEffect(() => {
        let d = new Date();
        setYear(d.getFullYear().toString());
    }, []);

    return (
        <>
            <footer>
                <div className="container">
                    <div className="footer_left">
                        <Link to="/"><img className="footer_logo" src={"/img/img_footer_logo.png" + queryStringTimestamp()} alt="フッターロゴ" /></Link>
						<div className="wrap">
						<div className="classroom first-classroom">
							<div className="classroom-txt">
								<span>＜nijico辻堂駅前＞</span>
								<Link className="insta-link" to="https://www.instagram.com/nijico_tsujido/" target="_blank" rel="noopener noreferrer"><img src={"/img/img_instagram.png" + queryStringTimestamp()} alt="Instagramアイコン" /></Link>
							</div>
							<div className="address">〒251-0047<br />神奈川県藤沢市辻堂2丁目7-16<br />SHONAN SAKURAI BUILLDING 3F</div>
							<div className="tel-reception">電話受付 : 10:30~17:15 (定休：日・月)</div>
							<div className="tel"><img src={"/img/img_footer_tel.png" + queryStringTimestamp()} alt="電話アイコン" /><span>0466-47-6730</span></div>
							<div className="mail-address">
								<img src={"/img/img_footer_mail.png" + queryStringTimestamp()} alt="メールアイコン" />
								<span>info<img className="atmark" src={"/img/atmark.gif" + queryStringTimestamp()} alt="atmark" />nijico-kind.com</span>
							</div>
						</div>
						<div className="classroom second-classroom">
							<div className="classroom-txt">
								<span>＜nijico湘南台＞</span>
								<Link className="insta-link" to="https://www.instagram.com/nijico_shonandai/" target="_blank" rel="noopener noreferrer"><img src={"/img/img_instagram.png" + queryStringTimestamp()} alt="Instagramアイコン" /></Link>
							</div>
							<div className="address">〒252-0804<br />神奈川県藤沢市湘南台2-26-18<br />サザンヒル1F</div>
							<div className="tel-reception">電話受付 : 10:30~17:15 (定休：日・月)</div>
							<div className="tel"><img src={"/img/img_footer_tel.png" + queryStringTimestamp()} alt="電話アイコン" /><span>0466-21-9840</span></div>
							<div className="mail-address">
								<img src={"/img/img_footer_mail.png" + queryStringTimestamp()} alt="メールアイコン" />
								<span>shonandai<img className="atmark" src={"/img/atmark.gif" + queryStringTimestamp()} alt="atmark" />nijico-kind.com</span>
							</div>
						</div>
						</div>
                    </div>
                    <nav className="footer_right">
                        <ul className='footer_menu'>
							<li>
								<Link to="/" onClick={ (e) => e.preventDefault() }>教室情報</Link>
								<ul className="child-menu">
                                    <li>
                                        <Link to="/classroom/classroom1">
                                            <p className="child-jp">ｰ nijico辻堂駅前</p>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/classroom/classroom2">
                                            <p className="child-jp">ｰ nijico湘南台</p>
                                        </Link>
                                    </li>
                                </ul>
							</li>
							<li><Link to="/guide">ご利用ガイド</Link></li>
							<li><Link to="/attempt">とりくみ</Link></li>
							<li><Link to="/recruit">採用情報</Link></li>
							<li><Link to="/company">施設概要</Link></li>
							<li><Link to="/about">発達支援・放デイについて</Link></li>
							<li><Link to="/contact">お問い合わせ</Link></li>
						</ul>
                    </nav>
                </div>
                <div className="copyright">
					<small>&copy;nijico {year} All Rights Reserved.</small>
				</div>
            </footer>
        </>
    )
}

export default Footer