import React from 'react'
import { Link } from 'react-router-dom';
import { queryStringTimestamp } from './Utils';
import CmnContactArea from './CmnContactArea';
import { Helmet } from 'react-helmet-async';
import ScrollTopIcon from './ScrollTopIcon';
import Loading from './Loading';
import ScrollTopTran from './ScrollTopTran';
import Header from './Header';
import Footer from './Footer';

const Guide = () => {
    return (
        <>
            <Helmet>
                <title>ご利用ガイド | nijico</title>
                <meta name="description" content="nijicoをご利用される際は、一度ご利用ガイドをご一読の上、ご連絡ください。" />
            </Helmet>
            <ScrollTopTran />
            <Loading />
            <Header />
            <main>
                <section id="guide-visual">
                    <h1>ご利用ガイド</h1>
                </section>

                <section id="panlist">
                    <div className="container">
                        <ul>
                            <li className="home"><Link to="/">ホーム</Link>&nbsp;</li>
                            <li>＞&nbsp;ご利用ガイド</li>
                        </ul>
                    </div>
                </section>

                <section id="flow">
                    <div className="container">
                        <h2>ご利用までの流れ</h2>
                        <div className="wrap_pc">
                            <div className="step">
                                <div className="img_star">
                                    <img src={"/img/img_guide_flow1.png" + queryStringTimestamp()} alt="ステップ１のイメージ" />
                                </div>
                                <div className="sentence tsuku-medium">
                                    <h3>STEP-01 <span className="text">お問い合わせ</span></h3>
                                    <div className="textBody">
                                        <p>見学や体験のお問い合わせの申し込みを随時承っております。</p>
                                        <p>不安に思っていること等お気軽にご相談ください。</p>
                                    </div>
                                </div>
                            </div>
                            <div className="step">
                                <div className="img_star">
                                    <img src={"/img/img_guide_flow2.png" + queryStringTimestamp()} alt="ステップ２のイメージ" />
                                </div>
                                <div className="sentence tsuku-medium">
                                    <h3>STEP-02 <span className="text">見学・体験</span></h3>
                                    <div className="textBody">
                                        <p>見活動内容や取り組みなどを詳しくご説明いたします。</p>
                                        <p>保護者様との面談の際は、ほかのお子様と一緒に過ごしていただくこともできます。</p>
                                        <p>ご見学後は、お子様の状態やご利用日数・時間のご希望をお伺いいたします。</p>
                                    </div>
                                </div>
                            </div>
                            <div className="step">
                                <div className="img_star">
                                    <img src={"/img/img_guide_flow3.png" + queryStringTimestamp()} alt="ステップ３のイメージ" />
                                </div>
                                <div className="sentence tsuku-medium">
                                    <h3>STEP-03 <span className="text">受給者証発行<span className="text">・手続き</span></span></h3>
                                    <div className="textBody">
                                        <p>※すでに受給者証をお持ちの方は、こちらの手続きは不要になります。</p>
                                        <p>事業所利用を始めるにあたって、「受給者証」が必要になります。</p>
                                        <p>ご利用をお考えでまだ交付を受けていない方は、お住まいの地域の市役所または区役所にて申請いただきます。わからないことがございましたらお気軽にご相談ください。</p>
                                        <p>発行されましたら、利用される事業所までご連絡ください。その際に契約日数等の調整をさせていただきます。</p>
                                    </div>
                                </div>
                            </div>
                            <div className="step">
                                <div className="img_star">
                                    <img src={"/img/img_guide_flow4.png" + queryStringTimestamp()} alt="ステップ４のイメージ" />
                                </div>
                                <div className="sentence tsuku-medium">
                                    <h3>STEP-04 <span className="text">入所申込</span></h3>
                                    <div className="textBody">
                                        <p>施設のサービス内容や災害時対応などの重要事項を説明させていただきます。</p>
                                        <p>ご契約の際、お子様についてのヒヤリングを行い、それらをもとに個別支援計画の作成を行い、サービスの提供を行っていきます。</p>
                                    </div>
                                </div>
                            </div>
                            <div className="step">
                                <div className="img_star">
                                    <img src={"/img/img_guide_flow5.png" + queryStringTimestamp()} alt="ステップ５のイメージ" />
                                </div>
                                <div className="sentence tsuku-medium">
                                    <h3>STEP-05  <span className="text">｢nijico｣<span className="text">ご利用開始</span></span></h3>
                                    <div className="textBody">
                                        <p>受給者証を受けている方は、ご利用開始できます。</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="wrap_sp">
                            <figure><img src={"/img/img_flow_all_sp.png" + queryStringTimestamp()} alt="フロー図" /></figure>
                            <div className="stepBody">
                                <div className="sentence tsuku-medium">
                                    <h3>STEP-01 <span className="text">お問い合わせ</span></h3>
                                    <div className="textBody">
                                        <p>見学や体験のお問い合わせの申し込みを随時承っております。</p>
                                        <p>不安に思っていること等お気軽にご相談ください。</p>
                                    </div>
                                </div>
                                <div className="sentence tsuku-medium">
                                    <h3>STEP-02 <span className="text">見学・体験</span></h3>
                                    <div className="textBody">
                                        <p>見活動内容や取り組みなどを詳しくご説明いたします。</p>
                                        <p>保護者様との面談の際は、ほかのお子様と一緒に過ごしていただくこともできます。</p>
                                        <p>ご見学後は、お子様の状態やご利用日数・時間のご希望をお伺いいたします。</p>
                                    </div>
                                </div>
                                <div className="sentence tsuku-medium">
                                    <h3>STEP-03 <span className="text">受給者証発行<span className="text">・手続き</span></span></h3>
                                    <div className="textBody">
                                        <p>※すでに受給者証をお持ちの方は、こちらの手続きは不要になります。</p>
                                        <p>事業所利用を始めるにあたって、「受給者証」が必要になります。</p>
                                        <p>ご利用をお考えでまだ交付を受けていない方は、お住まいの地域の市役所または区役所にて申請いただきます。わからないことがございましたらお気軽にご相談ください。</p>
                                        <p>発行されましたら、利用される事業所までご連絡ください。その際に契約日数等の調整をさせていただきます。</p>
                                    </div>
                                </div>
                                <div className="sentence tsuku-medium">
                                    <h3>STEP-04 <span className="text">入所申込</span></h3>
                                    <div className="textBody">
                                        <p>施設のサービス内容や災害時対応などの重要事項を説明させていただきます。</p>
                                        <p>ご契約の際、お子様についてのヒヤリングを行い、それらをもとに個別支援計画の作成を行い、サービスの提供を行っていきます。</p>
                                    </div>
                                </div>
                                <div className="sentence tsuku-medium">
                                    <h3>STEP-05  <span className="text">｢nijico｣<span className="text">ご利用開始</span></span></h3>
                                    <div className="textBody">
                                        <p>受給者証を受けている方は、ご利用開始できます。</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>

                <section id="time">
                    <div className="container">
                        <h2>サービス提供時間</h2>
                        <div className="time_flex">
                            <picture>
                                <source media="(min-width: 501px)" srcSet={"/img/img_guide_schedule.png" + queryStringTimestamp()} />
                                <source media="(max-width: 500px)" srcSet={"/img/img_guide_schedule_sp1.png" + queryStringTimestamp()} />
                                <img src={"/img/img_guide_schedule.png" + queryStringTimestamp()} alt="サービス提供時間" />
                            </picture>
                            <div className="time-sp">
                                <img src={"/img/img_guide_schedule_sp2.png" + queryStringTimestamp()} alt="サービス提供時間" />
                            </div>
                        </div>
                    </div>
                </section>

                <section id="price">
                    <div className="container">
                        <h2>料金</h2>
                        <div className="lead">
                            <p>市区町村発行の<span className="text">受給者証があれば､</span></p>
                            <p>”<strong>1</strong>”割負担で<span className="text">ご利用いただけます！</span></p>
                            <img className="rainbow" src={"/img/img_guide_price_rainbow.png" + queryStringTimestamp()} alt="星のイメージ" />
                        </div>
                        <div className="guide_woman">
                            <img src={"/img/img_guide_price_woman.png" + queryStringTimestamp()} alt="親御さんのイメージ" />
                        </div>
                        <div className="lead2 tsuku-bold">
                            <p><span className="text">世帯所得により毎月の</span><span className="max text">最高負担額</span><span
                                    className="text">が決まっています｡</span>
                            </p>
                            <p><span className="text">※下記以外におやつ代等の負担が</span><span className="text">別途かかる場合があります｡</span></p>
                        </div>
                        <div className="table">
                            <div className="t_head">
                                <div className="c_head">非課税世帯<br /><span>（生活保護・低所得）</span></div>
                                <div className="c_head">世帯所得<br />約900万円まで</div>
                                <div className="c_head">世帯所得<br />約900万円以上</div>
                            </div>
                            <div className="t_body">
                                <div className="c_data"><span>0</span>円</div>
                                <div className="c_data"><span>4,600</span>円</div>
                                <div className="c_data"><span>37,200</span>円</div>
                            </div>
                        </div>
                        <div className="guide_girl">
                            <img src={"/img/img_guide_price_girl.png" + queryStringTimestamp()} alt="受給者証の説明をする女の子のイメージ" />
                        </div>
                    </div>
                </section>
                <CmnContactArea />
                <ScrollTopIcon />
            </main>
            <Footer />
        </>
    )
}

export default Guide